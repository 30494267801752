:root {
  --size-6: 6px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-14: 14px;
  --size-16: 16px;
  --size-18: 18px;
  --size-20: 20px;
  --size-22: 22px;
  --size-24: 24px;
  --size-30: 30px;
  --size-32: 32px;
  --size-40: 40px;
  --size-42: 42px;
  --size-48: 48px;
  --size-54: 54px;
  --size-64: 64px;
  --size-80: 80px;
  --size-134: 134px;

  --size-container-max-width-sm: 720px;
  --size-container-max-width-md: 768px;
  --size-container-max-width-lg: 1240px;

  --size-header-height-sm: 60px;
  --size-header-height-lg: 75px;

  --container-padding-sides-sm: calc(2.5vw + 24px);
  --container-padding-sides-md: 5vw;
}
