:root {
  /* black */
  --color-black: #0a0a0a;
  --color-black--03: rgba(10, 10, 10, 0.03);
  --color-black--10: rgba(10, 10, 10, 0.1);
  --color-black--20: rgba(10, 10, 10, 0.2);
  --color-black--30: rgba(10, 10, 10, 0.3);
  --color-black--60: rgba(10, 10, 10, 0.6);

  /* white */
  --color-white: #fff;
  --color-white--20: rgba(255, 255, 255, 0.2);
  --color-white--40: rgba(255, 255, 255, 0.4);
  --color-white--50: rgba(255, 255, 255, 0.5);

  /* gray */
  --color-gray-100: #e6e6e6;
  --color-gray-300: #aaa;
  --color-gray-400: #81818c;
  --color-gray-700: #333;

  /* yellow */
  --color-yellow-100: #fff6bd;
  --color-yellow-300: #fe8;
  --color-yellow-400: #ffe04c;
  --color-yellow-500: #ffe900;
  --color-yellow-700: #ffcd00;

  /* orange */
  --color-orange-100: #ffe0c7;
  --color-orange-300: #ffc688;
  --color-orange-400: #ffa758;

  /* red */
  --color-red-100: #ffdee2;
  --color-red-300: #ffb7bf;
  --color-red-400: #ff8c94;
  --color-red-500: #ff4947;
  --color-red-700: #eb0400;

  /* green */
  --color-green-100: #bcedd2;
  --color-green-300: #81d2a8;
  --color-green-400: #47ae87;
  --color-green-500: #01ac3a;
  --color-green-700: #01982b;

  /* blue */
  --color-blue-100: #d2e1ff;
  --color-blue-300: #9ebdf4;
  --color-blue-400: #6794e5;
  --color-blue-500: #00f;
  --color-blue-700: #0000a4;

  /* pastel */
  --color-background: #f0f6ff;
  --color-information: #f0f6ff;
  --color-success: #e5f4e9;
  --color-attention: #fffae5;
  --color-error: #ffecec;
  --color-box-shadow: #e6e6e6;
  --color-disabled: #e6e6e6;
  --color-border: #c8d8e8;
  --color-selected: #f2fbf5;
}
