:root {
  --font-family-base: 'Graphik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --font-family-heading: 'Produkt', Georgia, Times, 'Times New Roman', serif;

  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-24: 24px;
  --font-size-28: 28px;
  --font-size-32: 32px;
  --font-size-38: 38px;
  --font-size-42: 42px;
  --font-size-48: 48px;
  --font-size-52: 52px;
  --font-size-72: 72px;
  --font-size-82: 82px;
  --font-size-92: 92px;

  --font-weight-light: 300;
  --font-weight-base-400: 400;
  --font-weight-semibold: 600;
  --font-weight-bold: 800;

  --line-height-28: calc(36 / 28);
  --line-height-32: calc(42 / 32);
  --line-height-base: calc(28 / 18);
  --line-height-text: calc(26 / 16);
  --line-height-intro: calc(30 / 20);
  --line-height-heading: 1;
  --line-height-list: calc(24 / 18);
  --line-height-narrow: 1;
}
